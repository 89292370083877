.Join{
    display:flex;
    padding:0 2rem;
    gap:10rem;
}
.left-j{
    color:white;
    font-size:1rem;
    font-weight:bold;
    text-transform:uppercase;
    position:relative;
}

.left-j>div{
    display:flex;
    gap: 1rem;
}
.right-j{
    display:flex;
    justify-content:center;
    align-items:flex-end;
}
.email-container{
    display:flex;
    gap: 3rem;
    background-color:rgb(243, 126, 48);
    padding:1rem 2rem;
    border-radius:10px;
}
.email-container>input{
    background-color:transparent;
    border:none;
    outline:none;
    color:var(--lightgray);
}
::placeholder{
    color:var(--lightgray);
}
.btn-j{
   background-color: var(--gray);
   color:white;
   border-radius:10px;
}


@media screen and (max-width:768px){
    .Join{
        flex-direction:column;
        gap:1rem;
    }
    .left-j{
        font-size:x-large;
        flex-direction: column;
        
    }
    .right-j{
        padding: 2rem;
    }
    .left-j>div{
        justify-content:center;
    }
}